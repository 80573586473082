.float-none {
  float: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}
