@media (max-width: @screen-xs-max) {
  .hidden-xs {
    //  `576px` 以下隐藏
    display: none !important;
  }
}

@media (max-width: @screen-sm-max) {
  .hidden-sm {
    // `768px` 以下隐藏
    display: none !important;
  }
}

@media (max-width: @screen-md-max) {
  .hidden-md {
    // `992px` 以下隐藏
    display: none !important;
  }
}

@media (max-width: @screen-lg-max) {
  .hidden-lg {
    // `1200px` 以下隐藏
    display: none !important;
  }
}

@media (max-width: @screen-xl-max) {
  .hidden-xl {
    // `1600px` 以下隐藏
    display: none !important;
  }
}

@media (min-width: @mobile-min) {
  .hidden-pc {
    // PC端隐藏，默认：`768px` 以上
    display: none !important;
  }
}

@media (max-width: @mobile-max) {
  .hidden-mobile {
    // 移动端隐藏，默认：`768px` 以下
    display: none !important;
  }
}
