.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-y {
  &__auto {
    overflow-y: auto;
  }
  &__hidden {
    overflow-y: hidden;
  }
}
