.scrollbar-mixin(@element-name) {
  .scrollbar-inner-mixin(@enabled) when(@enabled=true) {
    @{element-name} {
      // FireFox
      scrollbar-color: @scrollbar-track-color @scrollbar-thumb-color;
      scrollbar-width: thin;
      // Other
      &::-webkit-scrollbar {
        width: @scrollbar-height;
        height: @scrollbar-width;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 @scrollbar-width @scrollbar-track-color;
      }
      &::-webkit-scrollbar-thumb {
        background-color: @scrollbar-thumb-color;
        outline: 1px solid #333;
      }
    }
  }
  .scrollbar-inner-mixin(@scrollbar-enabled);
}

// 自定义div scrollbar
@scrollbar-prefix: ~'.scrollbar';
@{scrollbar-prefix} {
  overflow: auto;

  &-x {
    overflow-x: auto;
    overflow-y: hidden;
  }
  &-y {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// scrollbar
// TODO: (.nz-measure-scrollbar-placeholder) https://github.com/NG-ZORRO/ng-zorro-antd/pull/2617
.scrollbar-mixin(
  ~'body, @{scrollbar-prefix}, .@{ant-prefix}-dialog-wrap, .@{ant-prefix}-drawer-body, .@{ant-prefix}-drawer-wrapper-body, .@{ant-prefix}-anchor-wrapper, textarea.@{ant-prefix}-input'
);

// table
.scrollbar-table-mixin(@enabled) when(@enabled=true) {
  .scrollbar-mixin(~'.@{ant-prefix}-table-header, .@{ant-prefix}-table-body');
}
.scrollbar-table-mixin(@scrollbar-table-enabled);
