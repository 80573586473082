
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-white {
  color: #fff !important;
}

.text-hover {
  cursor: pointer;
  &:hover {
    color: @primary-color !important;
  }
}

.primary {
  color: @primary-color;
}

// .for-each(@colors, {
//   .bg-@{adKey}-light {
//     @color-bg-light-color: extract(@adItem, @color-light-position);
//     e(~`genCommentTypeColor('background', '@{color-bg-light-color}')`);
//     background-color: @color-bg-light-color !important;
//   }
//   .bg-@{adKey} {
//     @color-bg-color: extract(@adItem, @color-basic-position);
//     e(~`genCommentTypeColor('background', '@{color-bg-color}')`);
//     background-color: @color-bg-color !important;
//   }
//   .bg-@{adKey}-dark {
//     @color-bg-dark-color: extract(@adItem, @color-dark-position);
//     e(~`genCommentTypeColor('background', '@{color-bg-dark-color}')`);
//     background-color: @color-bg-dark-color !important;
//   }

//   .bg-@{adKey}-light-h {
//     @color-bg-light-h-color: extract(@adItem, @color-light-position);
//     e(~`genCommentTypeColor('hoverBackground', '@{color-bg-light-h-color}')`);
//     transition: background-color 300ms;
//     &:hover { background-color: @color-bg-light-h-color !important; }
//   }
//   .bg-@{adKey}-h {
//     @color-bg-basic-h-color: extract(@adItem, @color-basic-position);
//     e(~`genCommentTypeColor('hoverBackground', '@{color-bg-basic-h-color}')`);
//     transition: background-color 300ms;
//     &:hover { background-color: @color-bg-basic-h-color !important; }
//   }
//   .bg-@{adKey}-dark-h {
//     @color-bg-dark-h-color: extract(@adItem, @color-dark-position);
//     e(~`genCommentTypeColor('hoverBackground', '@{color-bg-dark-h-color}')`);
//     transition: background-color 300ms;
//     &:hover { background-color: @color-bg-dark-h-color !important; }
//   }

//   .text-@{adKey}-light {
//     @color-text-light-color: extract(@adItem, @color-light-position);
//     e(~`genCommentTypeColor('text', '@{color-text-light-color}')`);
//     color: @color-text-light-color !important;
//   }
//   .text-@{adKey} {
//     @color-text-basic-color: extract(@adItem, @color-basic-position);
//     e(~`genCommentTypeColor('text', '@{color-text-basic-color}')`);
//     color: @color-text-basic-color !important;
//   }
//   .text-@{adKey}-dark {
//     @color-text-dark-color: extract(@adItem, @color-dark-position);
//     e(~`genCommentTypeColor('text', '@{color-text-dark-color}')`);
//   }
// });

// .for-each(@aliasColors, {
//   .bg-@{adKey}-light  {
//     @alias-bg-light-color: color(~`colorPalette('@{adValue}', @{color-light-index})`);
//     e(~`genCommentTypeColor('background', '@{alias-bg-light-color}')`);
//     background-color: @alias-bg-light-color !important;
//   }
//   .bg-@{adKey} {
//     e(~`genCommentTypeColor('background', '@{adValue}')`);
//     background-color: @adValue !important;
//   }
//   .bg-@{adKey}-dark   {
//     @alias-bg-dark-color: color(~`colorPalette('@{adValue}', @{color-dark-index})`);
//     e(~`genCommentTypeColor('background', '@{alias-bg-dark-color}')`);
//     background-color: @alias-bg-dark-color !important;
//    }

//   .bg-@{adKey}-h {
//     e(~`genCommentTypeColor('hoverBackground', '@{adValue}')`);
//     transition: background-color 300ms;
//     &:hover {
//       background-color: @adValue !important;
//     }
//   }

//   .text-@{adKey}-light  {
//     @alias-text-light-color: color(~`colorPalette('@{adValue}', @{color-light-index})`);
//     e(~`genCommentTypeColor('text', '@{alias-text-light-color}')`);
//     color: @alias-text-light-color !important;
//    }
//   .text-@{adKey} {
//     e(~`genCommentTypeColor('text', '@{adValue}')`);
//     color: @adValue !important;
//   }
//   .text-@{adKey}-dark   {
//     @alias-text-dark-color: color(~`colorPalette('@{adValue}', @{color-dark-index})`);
//     e(~`genCommentTypeColor('text', '@{alias-text-dark-color}')`);
//     color: @alias-text-dark-color !important;
//   }
// });

// // grey
// .for-each(@greyColorer, {
//   .bg-grey-@{adKey} {
//     e(~`genCommentTypeColor('background', '@{adValue}')`);
//     background-color: @adValue !important;
//   }
//   .bg-grey-@{adKey}-h {
//     e(~`genCommentTypeColor('hoverBackground', '@{adValue}')`);
//     transition: background-color 300ms;
//     &:hover {
//       background-color: @adValue !important;
//     }
//   }
//   .text-grey-@{adKey} {
//     e(~`genCommentTypeColor('text', '@{adValue}')`);
//     color: @adValue !important;
//   }
// });

// .all-colors-minin(@enabled) when (@enabled = true) {
//   .loop-no(@adKey, @adValue, @i: 1) when (@i =< length(@color-no-list)) {
//     @name: '@{adKey}-@{i}';  // red-1, red-2, red-3 ....
//     @nameValue: e(@@name);
//     .bg-@{adKey}-@{i} {
//       e(~`genCommentTypeColor('background', '@{nameValue}')`);
//       background-color: @nameValue !important;
//     }
//     .bg-@{adKey}-@{i}-h {
//       e(~`genCommentTypeColor('hoverBackground', '@{nameValue}')`);
//       transition: background-color 300ms;
//       &:hover {
//         background-color: @nameValue !important;
//       }
//     }
//     .text-@{adKey}-@{i} {
//       e(~`genCommentTypeColor('text', '@{nameValue}')`);
//       color: @nameValue !important;
//     }
//     .loop-no(@adKey, @adValue, @i + 1);
//   }

//   .for-each(@colors, {
//     .loop-no(@adKey, @adValue, 1);
//   });
// }
// .all-colors-minin(@enable-all-colors);
