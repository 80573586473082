.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

// 设置文本为小写
.text-lowercase {
  text-transform: lowercase !important;
}

// 设置文本为大写
.text-uppercase {
  text-transform: uppercase !important;
}

// 设置每个单词以大写字母开头
.text-capitalize {
  text-transform: capitalize !important;
}

// 设置文本为删除线
.text-deleted {
  text-decoration: line-through;
}

// 文本不会换行，文本会在在同一行上继续，直到遇到 `<br>` 标签为止
.text-nowrap {
  white-space: nowrap !important;
}

// 保留空白符序列，但是正常地进行换行
.text-wrap {
  white-space: pre-wrap !important;
}

// 截短文本，并以 `...` 结尾
.text-truncate {
  .text-truncate();
}

// 普通字体粗细
.font-weight-normal {
  font-weight: normal;
}

// 粗体
.font-weight-bold {
  font-weight: 700;
}

// 斜体
.font-italic {
  font-style: italic;
}

// 12px
.text-xs {
  font-size: @text-xs !important;
}

// 14px
.text-sm {
  font-size: @text-sm !important;
}

// 16px
.text-md {
  font-size: @text-md !important;
}

// 18px
.text-lg {
  font-size: @text-lg !important;
}

// 22px
.text-xl {
  font-size: @text-xl !important;
}

// 28px
.text-xxl {
  font-size: @text-xxl !important;
}

.text-rtl-mixin(@enabled) when(@enabled=true) {
  [dir='rtl'] {
    .text-left {
      text-align: right !important;
    }

    .text-right {
      text-align: left !important;
    }
  }
}
.text-rtl-mixin(@rtl-enabled);
