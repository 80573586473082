.layout-logo__content {
  margin: 0 auto;
  height: 100%;
  padding-top: 70px;
  width: 392px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.ant-empty-description {
  color: rgba(0,0,0,.25);
}

.ez-badge-un-submit {
  .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ez-model-form-body {
  padding: 24px 24px 0;
}

.ez-model-form-footer {
  padding: 0 24px;
  height: 52px;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.ez-tree-dropdown {
  max-height: 300px;
}

.ez-tree-disbaled-dropdown {
  .ant-select-tree-treenode-disabled {
    .ant-select-tree-node-content-wrapper {
      color: inherit !important;
      cursor: pointer !important;
    }
  }
}
