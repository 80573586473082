// 28px
.icon-sm {
  font-size: @icon-sm !important;
}

// 56px
.icon-md {
  font-size: @icon-md !important;
}

// 84px
.icon-lg {
  font-size: @icon-lg !important;
}

// 112px
.icon-xl {
  font-size: @icon-xl !important;
}

// 140px
.icon-xxl {
  font-size: @icon-xxl !important;
}
