.point {
  cursor: pointer;
}

.no {
  &-resize {
    // 元素不能被用户缩放
    min-width: 100%;
    max-width: 100%;
    resize: none;
  }
  &-data {
    // 无数据元素
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 64px;
    text-align: center;
    i {
      position: relative;
      top: 3px;
      margin-right: 16px;
      font-size: 24px;
    }
  }
}

// 块元素水平居中
.block-center {
  margin: 0 auto;
}

// 背景垂直居中
.bg-center {
  background-position: center center;
  background-size: cover;
}

// 页面 loading
.page-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  padding-top: 200px;
  text-align: center;
  background: @background-color-base;
}

.color-weak {
  display: block;
  filter: invert(80%);
}

// 禁用样式，不可响应任何事件
.disabled {
  color: @disabled-color;
  pointer-events: none;
}

.del:hover{
  cursor: pointer;
  color: #ff4d4f;
}

.width-p-100 {
  width: 100%;
}

.height-p-100 {
  height: 100%;
}

.card-container {
  padding: 20px;
}

.mg-l-8 {
  margin-left: 8px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-l-12 {
  margin-left: 12px;
}

.mg-l-16 {
  margin-left: 16px;
}

.mg-l-20 {
  margin-left: 20px;
}

.mg-t-24 {
  margin-top: 24px;
}

.mg-t-16 {
  margin-top: 16px;
}

.mg-b-24 {
  margin-bottom: 24px;
}

.mg-0 {
  margin: 0 !important;
}

.pd-l-10 {
  padding-left: 10px;
}

.pd-0 {
  padding: 0;
}

.font-12 {
  font-size: 12px;
}
